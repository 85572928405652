import ReactPlayer from 'react-player/lazy'
import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useSpring, animated } from 'react-spring'
import { Icon } from '/components/buildingBlocks/Icon'

import playIcon from '/images/icons/play.raw.svg'
import styles from './Video.css'

const playerConfig = {
  vimeo: {
    playerOptions: {
      title: false,
    },
  },
}

export function Video({ sources, poster, layoutClassName = undefined }) {
  const [playing, setPlaying] = React.useState(false)
  const [hasInteracted, setHasInteracted] = React.useState(false)
  const isMounted = useRenderOnMount()
  const videoRef = React.useRef(null)
  const { src } = useVideoQualityLevel({ sources })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {!hasInteracted && (
        <>
          <PlayButtonAndTitle
            {...{ playing }}
            layoutClassName={styles.playButtonLayout}
            onClick={() => setPlaying(!playing)}
          />
          {poster && <img className={styles.image} src={poster} role='presentation' alt='' />}
        </>
      )}
      <div className={styles.playerContainer}>
        {isMounted && (
          <ReactPlayer
            width="100%"
            height="100%"
            onPlay={handlePlay}
            onPause={handlePause}
            ref={videoRef}
            url={src}
            config={playerConfig}
            controls
            // eslint-disable-next-line @kaliber/layout-class-name
            className={styles.player}
            {... { playing }}
          />
        )}
      </div>
    </div>
  )

  function handlePlay() {
    setPlaying(true)
    setHasInteracted(true)
  }

  function handlePause() {
    setPlaying(false)
  }
}

function PlayButtonAndTitle({ onClick, layoutClassName, playing }) {
  const spring = useSpring({
    opacity: playing ? 0 : 1
  })

  return (
    <animated.button
      style={spring}
      aria-label={playing ? 'pause' : 'play'}
      className={cx(styles.componentPlayButtonAndTitle, layoutClassName)}
      {...{ onClick }}
    >
      <span className={styles.buttonContainer}>
        <Icon icon={playIcon} />
      </span>
    </animated.button>
  )
}
